import AssetDrawer from "@/admin/asset/drawer/AssetDrawer"
import AutomationDrawer from "@/admin/automation/drawer/AutomationDrawer"
import { AutomationTemplateKind } from "@/admin/automation/drawer/__generated__/AutomationDrawerContentMutation.graphql"
import ChatBotDrawer from "@/admin/integrations/chat-bot/ChatBotDrawer"
import CopilotBotSettingsDrawer from "@/admin/integrations/copilot/CopilotBotSettingsDrawer"
import ConnectSSODrawer from "@/admin/integrations/sso/ConnectSSODrawer"
import RoleDrawer from "@/admin/members/roles/drawer/RoleDrawer"
import PathwaySettingsDrawer from "@/admin/pathways/settings/PathwaySettingsDrawer"
import BookmarksDrawer from "@/bookmarks/drawer/BookmarksDrawer"
import { BookmarksTab } from "@/bookmarks/drawer/BookmarksSubtabs"
import CertificatesDrawer from "@/certificates/drawer/CertificatesDrawer"
import { ContentSystemTaskKind } from "@/content-usage/__generated__/ContentUsageUtils_useNavigateToNextContentUsageFragment.graphql"
import ContentUsageDrawer from "@/content-usage/drawer/ContentUsageDrawer"
import { ContentType } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import { ContentUsageEntity } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplate_UpdateContentUsageMutation.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import AdminContentDrawer from "@/content/drawer/AdminContentDrawer"
import PostDrawer from "@/content/post/PostDrawer"
import { ContentTemplateKind } from "@/content/util/contentTemplates"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerProvider_CommunityEventQuery } from "@/core/context/__generated__/GlobalDrawerProvider_CommunityEventQuery.graphql"
import { GlobalDrawerProvider_HistoricalSlugQuery } from "@/core/context/__generated__/GlobalDrawerProvider_HistoricalSlugQuery.graphql"
import { OrganizationRole } from "@/core/root-app/__generated__/AppWithContextProvidersQuery.graphql"
import AdminMembershipPlanDrawer from "@/membership-plan/report/AdminMembershipPlanDrawer"
import MembershipPlanSettingsDrawer from "@/membership-plan/settings/MembershipPlanSettingsDrawer"
import NotificationCenterDrawer from "@/notification/drawer/NotificationCenterDrawer"
import { NotificationCenterTab } from "@/notification/drawer/NotificationCenterSubtabs"
import EventDrawer from "@/organization/occurrence/EventDrawer"
import PathwayGroupProductRegistrationDrawer from "@/pathway/pathway-group-product-registration/PathwayGroupProductRegistrationDrawer"
import ScheduledPostsDrawer from "@/post/list/ScheduledPostsDrawer"
import { CheckoutStep } from "@/product/checkout/store/CheckoutStore"
import MemberGroupDrawer from "@/product/common/member-group/common/member-group-drawer/MemberGroupDrawer"
import RegistrationDrawer from "@/product/register/RegistrationDrawer"
import MemberReportDrawer from "@/product/reports/drawer/MemberReportDrawer"
import { MemberReportDrawerSubtab } from "@/product/reports/drawer/MemberReportDrawerSubtabs"
import ExperienceSettingsDrawer from "@/product/settings/ExperienceSettingsDrawer"
import { isWebViewContentPage } from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ProductRole } from "@/role/__generated__/UpdateProductRoleButtonFragment.graphql"
import ProfileSettingsDrawer from "@/user/settings/ProfileSettingsDrawer"
import { QueryParamAction, useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import React, { useCallback, useEffect } from "react"
import { graphql } from "relay-runtime"

export type ProfileSettingsTab =
  | "profile"
  | "details"
  | "notifications"
  | "registrations"
  | "billing"
  | "account"
  | "connections"

export type ExperienceSettingsTabs =
  | "details"
  | "availability"
  | "registration"
  | "pricing"
  | "notifications"
  | "groups"
  | "team"

export type MembershipPlanSettingsTab = "details" | "spaces"

export type ExperienceSettingsSections = "meta-details" | undefined

export type EventDrawerSubtab =
  | "details"
  | "attendance-report"
  | "feedback-report"
  | "settings"
  | "members"
  | "invites"

export type ContentUsageDrawerSubtab =
  | "details"
  | "settings"
  | "submissions"
  | "scorm-completions"
  | "comments"
  | undefined
export type ContentUsageSubmissionsDrawerSubtab = "overview" | "submissions" | undefined
export type ContentUsageScormCompletionsDrawerSubtab = "overview" | undefined
export type ContentUsageQuizDrawerSubtab =
  | "questions"
  | "questions-editor"
  | "submissions-overview"
  | "submissions-detail"
  | "submissions-summaries"
  | undefined
export type AssetDrawerTab = "summary" | "chapters" | "transcript" | "subtitles"
export type PathwaySettingsTab = "details" | "availability" | "sequence"
export type PathwaySettingsSection = "meta-details" | undefined

/** Renders all global drawers triggered via query params. It is the responsibility of each drawer to open/close itself based on params */
export const GlobalDrawerProvider: React.FC = ({ children }) => {
  const activeOrganizaton = useActiveOrganization()

  useGlobalDrawerContentUsageRedirect()
  useGlobalDrawerCommunityEventRedirect()

  // Don't render the global drawers outside of activeOrganization. If you set drawer
  // query params but not the proper domain we don't want to render the drawer since
  // the drawer contents expect an activeOrganization set.
  if (!activeOrganizaton) return <>{children}</>

  // Do not open the global drawer for content that may already be rendered when on the webview content page
  let ALLOWED_DRAWERS = GLOBAL_DRAWERS
  for (const drawer of WEBVIEW_CONTENT_DRAWERS) {
    if (!isWebViewContentPage()) continue
    if (!location.pathname.includes(drawer)) continue
    ALLOWED_DRAWERS = Object.fromEntries(
      Object.entries(GLOBAL_DRAWERS).filter(([key]) => key !== drawer)
    ) as typeof GLOBAL_DRAWERS
  }

  return (
    <>
      {Object.entries(ALLOWED_DRAWERS).map(([key, { Component }]) => (
        <Component key={key} />
      ))}
      {children}
    </>
  )
}

/**
 * The config for all drawers
 *
 * The key is the name of the query param that will trigger the drawer
 *
 * Component: The component to render
 * params: default params for the drawer, all params with defaults of empty strings are required for drawer to be open
 * optionalParams: The params that are optional for the passing extra information to the drawer, not included in the default query params
 * openForAnyParams: The params that if any are present, the drawer should open
 */

const GLOBAL_DRAWERS = {
  automation: {
    Component: AutomationDrawer,
    params: {
      automationId: "" as GlobalID,
      automationTemplate: "" as AutomationTemplateKind,
    } as {
      automationId?: GlobalID
      automationTemplate?: AutomationTemplateKind
    },
    optionalParams: ["automationId", "automationTemplate"],
    openForAnyParams: ["automationId", "automationTemplate"],
  },
  adminContent: {
    Component: AdminContentDrawer,
    params: {
      contentId: "" as GlobalID,
    } as {
      contentId: GlobalID
      contentTemplate?: ContentTemplateKind
      view?: "questions" | "overview"
      drawerAIPrompt?: string
      drawerAIReferenceUrl?: string
      drawerAIReferenceEmbeddingSourceIds?: string
      drawerAIReferenceModuleContentUsageId?: GlobalID
      brIds?: string
    },
    optionalParams: [
      "contentTemplate",
      "view",
      "drawerAIPrompt",
      "drawerAIReferenceUrl",
      "drawerAIReferenceEmbeddingSourceIds",
      "drawerAIReferenceModuleContentUsageId",
      "brIds",
    ],
  },
  memberReport: {
    Component: MemberReportDrawer,
    params: {
      productMembershipId: "" as GlobalID,
      drawerTab: "summary" || "",
    } as {
      productMembershipId: GlobalID
      drawerTab?: MemberReportDrawerSubtab
    },
    optionalParams: [],
  },
  contentUsage: {
    // See useContentUsageDrawer for example of how to use this drawer
    Component: ContentUsageDrawer,
    params: {
      u: "",
    } as {
      u: string // usage id in the form of UUID to simplify drawer params
      drawerTab?: ContentUsageDrawerSubtab
      /** assignment/quiz submissions */
      submissionId?: GlobalID
      // Params that are only necessary for creation of new content usage
      drawerModuleContentUsageId?: GlobalID
      drawerUsageEntityId?: GlobalID
      drawerUsageEntity?: ContentUsageEntity
      drawerContentType?: ContentType
      drawerContentSystemTaskKind?: ContentSystemTaskKind
      drawerAIPrompt?: string
      drawerAIReferenceUrl?: string
      drawerAIReferenceEmbeddingSourceIds?: string
      drawerAIReferenceModuleContentUsageId?: GlobalID
      contentTemplate?: ContentTemplateKind
      drawerSubmissionsTab?: ContentUsageSubmissionsDrawerSubtab
      drawerScormCompletionsTab?: ContentUsageScormCompletionsDrawerSubtab
      drawerQuizTab?: ContentUsageQuizDrawerSubtab
      drawerQuizUseAI?: "0" | "1"
      drawerQuizRetry?: "0" | "1"
      drawerConnectSlackTab?: "connect-slack"
      brIds?: string
    },
    optionalParams: [
      "drawerTab",
      "submissionId",
      "drawerModuleContentUsageId",
      "drawerUsageEntityId",
      "drawerUsageEntity",
      "drawerContentType",
      "drawerContentSystemTaskKind",
      "drawerAIPrompt",
      "drawerAIReferenceUrl",
      "drawerAIReferenceEmbeddingSourceIds",
      "drawerAIReferenceModuleContentUsageId",
      "contentTemplate",
      "drawerSubmissionsTab",
      "drawerScormCompletionsTab",
      "drawerQuizTab",
      "drawerQuizRetry",
      "drawerConnectSlackTab",
      "commentOrder",
      "brIds",
    ],
  },
  chatBot: {
    Component: ChatBotDrawer,
    params: {
      cb: "",
    } as {
      cb: "new" | GlobalID
    },
    optionalParams: [],
  },
  experienceSettings: {
    Component: ExperienceSettingsDrawer,
    params: {
      drawerExperienceId: "",
      experienceSettingsTab: "details",
    } as {
      drawerExperienceId: GlobalID
      experienceSettingsTab: ExperienceSettingsTabs
      experienceSettingsSection?: ExperienceSettingsSections
    },
    optionalParams: ["experienceSettingsSection"],
  },
  profileSettings: {
    Component: ProfileSettingsDrawer,
    params: {
      drawerProfileId: "",
      profileSettingsTab: "profile",
      editProfile: "0",
    } as {
      drawerProfileId: GlobalID
      profileSettingsTab: ProfileSettingsTab
      editProfile?: "0" | "1"
    },
    optionalParams: [],
  },
  event: {
    Component: EventDrawer,
    params: {
      drawerOccurrenceId: "",
      drawerEventTab: "details",
      drawerShowShareModal: "0",
      drawerShowRecurringShareOptionsStep: "0",
      drawerRecentlyPublished: "0",
    } as {
      drawerOccurrenceId: GlobalID
      drawerEventTab: EventDrawerSubtab
      drawerShowShareModal?: "0" | "1"
      drawerShowRecurringShareOptionsStep?: "0" | "1"
      drawerRecentlyPublished?: "0" | "1"
      startRecording?: "true"
      temporaryRecordingAssetId?: GlobalID
    },
    optionalParams: ["startRecording", "temporaryRecordingAssetId"],
  },
  copilotSettings: {
    Component: CopilotBotSettingsDrawer,
    params: {
      botId: "",
    } as {
      botId: GlobalID
    },
    optionalParams: [],
  },
  registration: {
    Component: RegistrationDrawer,
    params: {
      drawerRegistrationExperienceId: "",
      drawerRegistrationOccurrenceId: undefined,
      registrationStep: "registration",
      gifting: "false",
    } as {
      drawerRegistrationExperienceId?: GlobalID
      drawerRegistrationOccurrenceId?: GlobalID
      registrationStep?: CheckoutStep
      gifting?: "false" | "true"
    },
    optionalParams: [],
  },
  pathwayGroupProductRegistration: {
    Component: PathwayGroupProductRegistrationDrawer,
    params: {
      /** pathway group id */
      drawerPGID: "",
      /** product id that belongs to the pathway group */
      pId: "",
    } as {
      drawerPGID?: GlobalID
      pId?: GlobalID
    },
    optionalParams: [],
  },
  role: {
    Component: RoleDrawer,
    params: {
      roleDrawerTab: "",
    } as {
      roleDrawerTab: "details" | "assign" | ""
      organizationRole?: OrganizationRole
      productRole?: ProductRole
    },
    optionalParams: ["organizationRole", "productRole"],
  },
  post: {
    Component: PostDrawer,
    params: {
      postId: "" as GlobalID,
    } as {
      postId: string
      scrollToComments?: "true"
    },
    optionalParams: ["scrollToComments", "commentOrder"],
  },

  scheduledPosts: {
    Component: ScheduledPostsDrawer,
    params: {
      feedId: "" as GlobalID,
    },
    optionalParams: [],
  },
  notificationCenter: {
    Component: NotificationCenterDrawer,
    params: {
      notifications: "all" as NotificationCenterTab,
      notificationsDrawer: "",
    },
    optionalParams: [],
  },
  bookmarks: {
    Component: BookmarksDrawer,
    params: {
      bookmarksTab: "content" as BookmarksTab,
      bookmarksDrawer: "",
    },
    optionalParams: [],
  },
  certificates: {
    Component: CertificatesDrawer,
    params: {
      drawerCertificatesProductId: "",
    } as {
      certificateDrawerTab?: "add" | ""
      drawerCertificatesProductId: GlobalID
      certificateId?: GlobalID
    },
    optionalParams: ["certificateId", "certificateDrawerTab"],
  },
  membershipPlanSettings: {
    Component: MembershipPlanSettingsDrawer,
    params: {
      drawerMembershipPlanId: "",
      membershipPlanSettingsTab: "details",
    } as {
      drawerMembershipPlanId: GlobalID
      membershipPlanSettingsTab: MembershipPlanSettingsTab
    },
    optionalParams: [],
  },
  membershipPlan: {
    Component: AdminMembershipPlanDrawer,
    params: {
      membershipPlanId: "",
    } as {
      membershipPlanId: GlobalID
    },
    optionalParams: [],
  },
  memberGroup: {
    Component: MemberGroupDrawer,
    params: {
      memberGroupId: "",
    } as {
      memberGroupId: GlobalID
    },
    optionalParams: [],
  },
  asset: {
    Component: AssetDrawer,
    params: {
      assetId: "",
      assetDrawerTab: "summary",
    } as {
      assetId: GlobalID
      assetDrawerTab?: AssetDrawerTab
    },
    optionalParams: ["assetDrawerTab"],
  },
  pathwaySettings: {
    Component: PathwaySettingsDrawer,
    params: {
      pathwayProductId: "",
    } as {
      pathwayProductId: GlobalID
      pathwaySettingsTab?: PathwaySettingsTab
      pathwaySettingsSection?: PathwaySettingsSection
    },
    optionalParams: ["pathwaySettingsTab", "pathwaySettingsSection"],
  },
  connectSSO: {
    Component: ConnectSSODrawer,
    params: {
      isOpen: "",
    } as {
      isOpen: string
      currentStep?: string
      selectedIdp?: string
    },
    optionalParams: ["currentStep", "selectedIdp"],
  },
}

/** Drawers that can be viewed as full pages on the webview content page */
const WEBVIEW_CONTENT_DRAWERS: GlobalDrawerKind[] = ["bookmarks", "profileSettings"]

/** The config for all drawers */
export type GlobalDrawersConfig = typeof GLOBAL_DRAWERS

/** All available drawer names */
export type GlobalDrawerKind = keyof GlobalDrawersConfig

/** The params interface for a given drawer */
export type GlobalDrawerParams<K extends GlobalDrawerKind> =
  GlobalDrawersConfig[K]["params"]

/** Used to access methods for dealing with a global drawer */
export function useGlobalDrawer<K extends GlobalDrawerKind>(kind: K) {
  const [params, setParams] = useQueryParamState<GlobalDrawersConfig[K]["params"]>()
  const optionalParamsKeys = GLOBAL_DRAWERS[kind].optionalParams || []

  const requiredParams = Object.entries(GLOBAL_DRAWERS[kind].params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, defaultValue]) => defaultValue === "")
    .map(([p]) => p) as (keyof GlobalDrawerParams<K>)[]

  const handleClose = useCallback(() => {
    const paramsKeys = Object.keys(GLOBAL_DRAWERS[kind].params)
    const allParamsKeys = [...paramsKeys, ...optionalParamsKeys]
    setParams(
      // Empty all param values for the drawer.
      allParamsKeys.reduce((acc, param) => ({ ...acc, [param]: undefined }), {})
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kind, setParams])

  const openForAnyParams = (GLOBAL_DRAWERS[kind] as any).openForAnyParams || []
  const openForAny = openForAnyParams.map(
    (p: keyof GlobalDrawerParams<K>) => p as keyof GlobalDrawerParams<K>
  )
  const isOpen = openForAny.length
    ? openForAny.some((p: keyof GlobalDrawerParams<K>) => Boolean(params[p]))
    : requiredParams.every((p) => Boolean(params[p]))

  // Swap from one global drawer to another. Avoids a bug when using close and open
  // in quick succession where the closed drawer re-opens immediately.
  // NOTE: might be unnecessary now after useQueryParamState was changed to use window.location
  const handleSwap = useCallback(
    <NewK extends GlobalDrawerKind>(openParams: GlobalDrawersConfig[NewK]["params"]) => {
      // Set the new drawer kind's params and unset the old kind's params
      const newParams: GlobalDrawersConfig[NewK]["params"] &
        GlobalDrawersConfig[K]["params"] = openParams
      const oldKeys = Object.keys(GLOBAL_DRAWERS[kind].params)
      const allOldKeys = [...oldKeys, ...optionalParamsKeys]

      // For the webview content page, keep existing params
      if (isWebViewContentPage()) {
        setParams({ ...allOldKeys, ...newParams })
      } else {
        setParams(
          allOldKeys.reduce((acc, key) => ({ ...acc, [key]: undefined }), newParams)
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kind, setParams]
  )

  return {
    kind,
    params,
    isOpen,
    open: setParams,
    close: handleClose,
    swap: handleSwap,
    setParams: useCallback(
      (
        newParams: Partial<GlobalDrawersConfig[K]["params"]>,
        action?: QueryParamAction
      ) => {
        setParams({ ...params, ...newParams }, action)
      },
      [params, setParams]
    ),
  }
}

function useGlobalDrawerContentUsageRedirect() {
  const activeOrganization = useActiveOrganization()
  const contentUsageDrawer = useContentUsageDrawer()

  const [params] = useQueryParamState<{
    // Historic collection item drawer params
    drawerCollectionItemId?: GlobalID
    // Historic curriculum item drawer params
    drawerSectionId?: GlobalID
    drawerItemId?: GlobalID
  }>()
  const [_, setParams] = useQueryParamState<
    GlobalDrawerParams<"contentUsage"> & {
      drawerCollectionItemId?: GlobalID
      drawerSectionId?: GlobalID
      drawerItemId?: GlobalID
    }
  >()

  useEffect(() => {
    async function checkRedirect() {
      if (!params || !activeOrganization?.id) return

      const slug = params.drawerCollectionItemId ?? params.drawerItemId

      const data = await Relay.runQuery<GlobalDrawerProvider_HistoricalSlugQuery>(
        graphql`
          query GlobalDrawerProvider_HistoricalSlugQuery(
            $organizationId: String!
            $slug: String!
          ) {
            historicalSlug(organizationId: $organizationId, slug: $slug) {
              ... on SlugHistory {
                contentUsageId
              }
            }
          }
        `,
        {
          organizationId: activeOrganization.id,
          slug: slug || "",
        }
      )

      if (!data || !data.historicalSlug) return

      const { historicalSlug } = data

      if (!contentUsageDrawer.isOpen && historicalSlug.contentUsageId) {
        setParams({
          u: Relay.fromGlobalId(historicalSlug.contentUsageId).id,
          drawerCollectionItemId: undefined,
          drawerSectionId: undefined,
          drawerItemId: undefined,
        })
      }
    }

    if (!params) return

    const isCurriculumItemDrawerOpen = Boolean(
      params.drawerSectionId && params.drawerItemId
    )
    const isCollectionItemDrawerOpen = Boolean(params.drawerCollectionItemId)

    // If neither drawer is open, don't redirect
    if (!isCollectionItemDrawerOpen && !isCurriculumItemDrawerOpen) return

    checkRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeOrganization?.id,
    contentUsageDrawer.isOpen,
    params.drawerCollectionItemId,
    params.drawerSectionId,
    params.drawerItemId,
  ])
}

/** If the path params include a slug for an event (community event product slug),
 * find the occurrence ID for the next or last occurrence and redirect to event drawer
 */
function useGlobalDrawerCommunityEventRedirect() {
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const eventDrawer = useGlobalDrawer("event")

  const [params] = useQueryParamState<{
    // Community event drawer params
    drawerEventSlug?: string
    drawerOccurrenceId?: GlobalID
  }>()
  const [_, setParams] = useQueryParamState<
    GlobalDrawerParams<"event"> & {
      drawerEventSlug?: string
    }
  >()

  useEffect(() => {
    async function checkRedirect() {
      if (!params || !activeOrganization?.id) return
      const { drawerEventSlug, drawerOccurrenceId } = params

      if (activeProduct?.type === "community_event" && drawerOccurrenceId) {
        setParams({
          drawerOccurrenceId,
          drawerEventTab: "details",
        })
        return
      }

      const data = await Relay.runQuery<GlobalDrawerProvider_CommunityEventQuery>(
        graphql`
          query GlobalDrawerProvider_CommunityEventQuery(
            $organizationSlug: String!
            $slug: String
          ) {
            product(organizationSlug: $organizationSlug, slug: $slug) {
              ... on Product {
                nextOrLastOccurrence {
                  id
                }
              }
            }
          }
        `,
        { organizationSlug: activeOrganization.slug, slug: drawerEventSlug }
      )

      if (!data || !data.product) return
      const { product } = data

      if (product.nextOrLastOccurrence?.id) {
        setParams({
          drawerOccurrenceId: product.nextOrLastOccurrence.id,
          drawerEventTab: "details",
          drawerEventSlug: undefined,
        })
      }
    }

    if (!params) return

    const isCommunityEventDrawerOpen = Boolean(params.drawerEventSlug)

    // If drawer isn't open, don't redirect
    if (!isCommunityEventDrawerOpen) return

    checkRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization?.id, eventDrawer.isOpen, params.drawerEventSlug])
}
