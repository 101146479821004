import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { KoalaContextActiveOrganizationFragment$key } from "@/core/context/__generated__/KoalaContextActiveOrganizationFragment.graphql"
import { KoalaProvider, useKoala } from "@getkoala/react"
import { isProduction } from "@utils/globalVariables"
import React, { useEffect } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export const KoalaDiscoProvider: React.FC = ({ children }) => {
  const { authUser } = useAuthUser()
  const { koala } = useKoala()
  const activeOrganization = useActiveOrganization()
  const organization = useFragment<KoalaContextActiveOrganizationFragment$key>(
    graphql`
      fragment KoalaContextActiveOrganizationFragment on Organization {
        id
        viewerMembership {
          shouldInitiateKoala
        }
      }
    `,
    activeOrganization
  )

  useEffect(() => {
    // if auth user changes, re-identify the user with Koala
    initializeKoala()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?.id, koala])

  return <KoalaProvider publicApiKey={KOALA_PUBLIC_API_KEY}>{children}</KoalaProvider>

  function initializeKoala() {
    if (
      !isProduction() ||
      !authUser ||
      !organization ||
      !activeOrganization ||
      // only community admins + owners should be sent to Koala
      !activeOrganization?.viewerIsOwnerOrAdmin ||
      // do not initiate if the user has been added via Disco Control
      !organization.viewerMembership?.shouldInitiateKoala
    )
      return

    koala.identify(authUser.email)
  }
}
