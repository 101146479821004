import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  LabelKind,
  LabelsContextActiveOrganizationFragment$key,
} from "@/core/context/__generated__/LabelsContextActiveOrganizationFragment.graphql"
import { LabelsContext_Label$data } from "@/core/context/__generated__/LabelsContext_Label.graphql"
import Relay from "@/relay/relayUtils"
import { ArrayUtils } from "@utils/array/arrayUtils"
import React, { useContext } from "react"
import { graphql, useFragment } from "react-relay"

export type LabelData = Omit<LabelsContext_Label$data, " $fragmentType">

const LabelsContext = React.createContext<Record<LabelKind, LabelData> | null>(null)

export function useLabels() {
  const labels = useContext(LabelsContext)
  if (!labels) throw new Error("useLabel used outside of LabelsProvider context")
  return labels
}

export function useLabel(kind: LabelKind) {
  return useLabels()[kind] ?? { singular: "", plural: "" }
}

/**
 * @returns a function that replaces macros in format `<label.singular|plural>` for all labels in a string
 * @example processLabelsMacros("Course products label: <experience.plural>") => "Course products label: experiences"
 */
export function useProcessLabelsMacros() {
  const labels = useLabels()
  const labelKinds = Object.keys(labels).join("|")
  return function processLabelsMacros(text: string): string {
    const labelsMacroRegex = new RegExp(`<(${labelKinds})\\.(plural|singular)>`, "g")
    const [match, labelKind, singleOrPlural] = (labelsMacroRegex.exec(text) ?? []) as
      | [string, LabelKind, keyof LabelData]
      | []

    if (!labelKind || !singleOrPlural || !match) return text
    const label = labels[labelKind]
    const labelValue = label[singleOrPlural]
    const updated = text.replaceAll(match, labelValue)
    return processLabelsMacros(updated)
  }
}

const LabelsProvider: React.FC = (props) => {
  const { children } = props

  const organization = useFragment<LabelsContextActiveOrganizationFragment$key>(
    graphql`
      fragment LabelsContextActiveOrganizationFragment on Organization {
        labels {
          edges {
            node {
              id
              kind
              ...LabelsContext_Label @relay(mask: false)
            }
          }
        }
      }
    `,
    useActiveOrganization()
  )

  const labels = Relay.connectionToArray(organization?.labels)
  const labelsMap = ArrayUtils.mapBy(labels, "kind")

  return <LabelsContext.Provider value={labelsMap}>{children}</LabelsContext.Provider>
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment LabelsContext_Label on Label {
    kind
    singular
    plural
    description
  }
`

export default LabelsProvider
