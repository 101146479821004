/**
 * @generated SignedSource<<f1bbf85210759c2cedab4f377a802d7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CalendarConnectionPlatform = "ics" | "outlook" | "%future added value";
export type UserRegistrationStatus = "complete" | "partially_complete" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuthUserContextFragment$data = {
  readonly id: string;
  readonly timezone: string;
  readonly avatar: string;
  readonly email: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly fullName: string;
  readonly bio: string;
  readonly cover: string | null;
  readonly creationDatetime: string;
  readonly isVerified: boolean;
  readonly hasAcceptedTerms: boolean;
  readonly registrationStatus: UserRegistrationStatus;
  readonly calendarConnection: {
    readonly id: string;
    readonly platform: CalendarConnectionPlatform;
  } | null;
  readonly lastActiveOrganizationMembership: {
    readonly organization: {
      readonly primaryDomain: string;
    };
  } | null;
  readonly authProviderId: string | null;
  readonly isStaff: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment" | "ProfileAvatarFragment" | "UserflowContextAuthUserFragment">;
  readonly " $fragmentType": "AuthUserContextFragment";
};
export type AuthUserContextFragment$key = {
  readonly " $data"?: AuthUserContextFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthUserContextFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthUserContextFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDatetime",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarWithDetailsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserflowContextAuthUserFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAcceptedTerms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CalendarConnection",
      "kind": "LinkedField",
      "name": "calendarConnection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "lastActiveOrganizationMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primaryDomain",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authProviderId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStaff",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "d90b341f286f3ae802725f3a28eedbb2";

export default node;
