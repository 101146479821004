/**
 * @generated SignedSource<<d3ad3631a247efece4ea6e37213d3967>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KoalaContextActiveOrganizationFragment$data = {
  readonly id: string;
  readonly viewerMembership: {
    readonly shouldInitiateKoala: boolean;
  } | null;
  readonly " $fragmentType": "KoalaContextActiveOrganizationFragment";
};
export type KoalaContextActiveOrganizationFragment$key = {
  readonly " $data"?: KoalaContextActiveOrganizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"KoalaContextActiveOrganizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KoalaContextActiveOrganizationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shouldInitiateKoala",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ea048d14007b48278e49b5f93ab97489";

export default node;
