/**
 * @generated SignedSource<<eb6c85f99e74f1a81342dede75d72f38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type CommunityTemplateKind = "basic_community" | "virtual_school" | "incubator" | "training_program" | "membership" | "association" | "product_enablement" | "internal_team" | "onboarding" | "upskilling" | "compliance" | "customer_education" | "partner_education" | "accelerator" | "virtual_academy" | "professional_association" | "membership_community" | "%future added value";
export type ValidateCommunityCreationStepInput = {
  step: string;
  organization?: CreateCommunityOrganizationInput | null;
  organizationDescription?: CreateCommunityOrganizationDescriptionInput | null;
  user?: UpdateUserProfileStepInput | null;
  attributes?: ReadonlyArray<CommunityAttributeInput> | null;
  template?: CommunityTemplateKind | null;
};
export type CreateCommunityOrganizationInput = {
  name: string;
  slug: string;
  logo?: string | null;
  timezone?: string | null;
  coverUrl?: string | null;
  description?: string | null;
  visibility?: string | null;
  template?: CommunityTemplateKind | null;
  badge?: BadgeInput | null;
};
export type BadgeInput = {
  icon?: string | null;
  color?: string | null;
  emoji?: string | null;
  mediaUrl?: string | null;
  assetId?: string | null;
  kind: BadgeKind;
};
export type CreateCommunityOrganizationDescriptionInput = {
  description?: string | null;
};
export type UpdateUserProfileStepInput = {
  firstName: string;
  lastName: string;
  bio?: string | null;
  avatar?: string | null;
  cover?: string | null;
};
export type CommunityAttributeInput = {
  name: string;
  value: string;
};
export type OnboardingContextValidateMutation$variables = {
  input: ValidateCommunityCreationStepInput;
};
export type OnboardingContextValidateMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type OnboardingContextValidateMutation = {
  variables: OnboardingContextValidateMutation$variables;
  response: OnboardingContextValidateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidateCommunityCreationStepResponse",
    "kind": "LinkedField",
    "name": "validateCommunityCreationStep",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingContextValidateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardingContextValidateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5199c5c0add301db8bb404f680b5dac4",
    "id": null,
    "metadata": {},
    "name": "OnboardingContextValidateMutation",
    "operationKind": "mutation",
    "text": "mutation OnboardingContextValidateMutation(\n  $input: ValidateCommunityCreationStepInput!\n) {\n  response: validateCommunityCreationStep(input: $input) {\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b695e883b53835632d15af856dbd63c3";

export default node;
