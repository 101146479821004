import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { isArrayOrObservableArray, isObject } from "@utils/function/functionUtils"
import cloneDeep from "lodash/cloneDeep"
import isEqual from "lodash/isEqual"
import {
  IObservableArray,
  ObservableSet,
  action,
  autorun,
  makeAutoObservable,
  observable,
  toJS,
} from "mobx"
import { deepObserve } from "mobx-utils"
import { useEffect, useState } from "react"
import { UseMutationConfig, useMutation } from "react-relay"
import {
  Disposable,
  GraphQLTaggedNode,
  MutationParameters,
  SelectorStoreUpdater,
  VariablesOf,
} from "relay-runtime"
import { GlobalID, ValidationError } from "../../../relay/RelayTypes"

interface FormSettings<T extends FormMutation<any>> {
  requireChangeToSubmit: boolean
  onCompleted?: (response: T["response"]["response"]) => void
  showErrorToast?: boolean
}

/** State manager for forms that submit a Relay mutation */
export default class FormStore<
  TState extends FormState,
  TMutation extends FormMutation<any> = any
> {
  /** Stored callback that executes the graphql mutation for the form */
  private commitMutation: CommitFormMutationFn<TMutation>

  private settings: FormSettings<TMutation>

  /** The initial or last-saved values of the form */
  initialState: ObservableState<TState>

  /** The current values of the form */
  state: ObservableState<TState>

  /** Errors from last form submission */
  errors = observable.array<ValidationError>()

  /** A random string that can be used as a key for components, changed on form reset */
  key: string

  isSubmitting = false

  constructor(
    initialState: TState,
    commitMutation: CommitFormMutationFn<TMutation>,
    settings: Partial<FormSettings<TMutation>>
  ) {
    // cloneDeep ensure that any nested arrays/objects are dereferenced
    // between the initialState and state objects.
    this.initialState = cloneDeep(initialState) as ObservableState<TState>
    this.state = cloneDeep(initialState) as ObservableState<TState>

    this.commitMutation = commitMutation
    this.key = Math.random().toString(36).substring(7)
    this.settings = {
      requireChangeToSubmit: settings.requireChangeToSubmit ?? true,
      onCompleted: settings.onCompleted,
    }
    makeAutoObservable(this, {}, { deep: true })
  }

  /** A map of field to error messages */
  get errorsByField(): Record<keyof TState | string, string[] | undefined> {
    return this.errors.reduce((map, error) => {
      const field = error.field as keyof TState
      map[field] = map[field] || []
      map[field]!.push(error.message)
      return map
    }, {} as Record<keyof TState, string[] | undefined>)
  }

  errorsByFields(...fields: (keyof TState | string)[]): string[] | undefined {
    let errors: string[] | undefined
    for (const error of this.errors) {
      if (!fields.includes(error.field)) continue
      errors = errors || []
      errors.push(error.message)
    }
    return errors
  }

  get isChanged(): boolean {
    return Object.keys(this.changedState).length > 0
  }

  /** Object containing only keys in state that changed from initialState */
  get changedState(): Partial<TState> {
    return this.getChangedState(this.initialState, this.state) as Partial<TState>
  }

  /** Update initial state to current state */
  resetInitialState() {
    Object.assign(this.initialState, toJS(this.state))
  }

  /** If true, the form is disabled */
  get disabled(): boolean {
    // Don't allow double submission.
    if (this.isSubmitting) return true
    // If there are no changes, the form should be disabled
    if (this.settings.requireChangeToSubmit && !this.isChanged) return true
    // Disable while there are errors to address.
    // After changing to fix errors, will be able to submit from the check above.
    return this.errors.length > 0
  }

  getChangedState<T extends FormState>(initialState: T, currentState: T): Partial<T> {
    const changedState: { [k: string]: unknown } = {}

    const keys = Array.from(
      new Set([...Object.keys(currentState), ...Object.keys(initialState)])
    )
    for (const key of keys) {
      const initial = initialState[key]
      const current = currentState[key]

      // For nested arrays, perform a deep comparison
      if (isArrayOrObservableArray(current) && isArrayOrObservableArray(initial)) {
        if (!isEqual(toJS(current.slice()), toJS(initial.slice()))) {
          changedState[key] = current
        }
        continue
      }

      // For nested objects, get changed state recursively
      if (isObject(current) || isObject(initial)) {
        const nestedChangedState = this.getChangedState(
          isObject(initial) ? initial : {},
          isObject(current) ? current : {}
        )
        if (Object.keys(nestedChangedState).length > 0) {
          changedState[key] = nestedChangedState
        }
        // eslint-disable-next-line no-continue
        continue
      }

      // Current "changed" check is strict equality.
      if (initial !== current) {
        changedState[key] = current
      }
    }

    return changedState as Partial<T>
  }

  /**
   * Submit the form's mutation with the provided input.
   * You can get the input from either form.state or form.changedState.
   * optionally provide a connection id to update relay store connection
   * @param {TData} input - form.state or form.changedState
   * @param {string[] | undefined} args.connections - connection `__id` from Relay, if form mutation updates a connection edge/node
   */
  submit<T extends InputFormMutation<TMutation>>(
    input: NoExtraProperties<InputFormMutation<TMutation>, T>,
    args: {
      connections?: string[]
      updater?: SelectorStoreUpdater<TMutation["response"]>
      optimisticUpdater?: SelectorStoreUpdater<TMutation["response"]>
      // Any variables that are not part of the mutation input
      variables?: Omit<VariablesOf<TMutation>, "input" | "connections">
    } = {},
    options: {
      checkErrors: boolean
    } = { checkErrors: false }
  ) {
    if (this.errors.length && options.checkErrors) {
      return { didSave: false, response: { node: null, data: null, errors: this.errors } }
    }

    this.isSubmitting = true
    return (
      new Promise<{ didSave: boolean; response?: TMutation["response"]["response"] }>(
        (resolve) => {
          this.commitMutation({
            variables: {
              input: toJS(observable(input)),
              connections: args.connections,
              ...args.variables,
            },
            onCompleted: action((res) => {
              // Check for errors in the mutation response.
              if (typeof res.response === "object") {
                const { errors } = res.response
                if (errors) {
                  this.errors.replace([...errors])
                  resolve({ didSave: false })
                  return
                }
              }
              // On success, clear errors and reset initialState
              this.errors.clear()
              this.resetInitialState()
              resolve({ didSave: true, response: res.response })
              this.settings.onCompleted?.(res.response)
            }),
            updater: args.updater,
            optimisticUpdater: args.optimisticUpdater,
            onError: action((err) => {
              console.error(err)
              this.errors.replace([
                {
                  field: "",
                  message: "An unexpected error occurred. Please try again later.",
                },
              ])
              resolve({ didSave: false })
            }),
          })
        }
      )
        // Clean up submitting state after mutation.
        .finally(action(() => (this.isSubmitting = false)))
    )
  }

  /** reset form to initial state */
  reset() {
    // Must clone deep to avoid observable reference issues
    Object.assign(this.state, cloneDeep(this.initialState))

    // Reset key to force re-render of form components that use it
    this.key = Math.random().toString(36).substring(7)

    // Clear errors
    this.errors.clear()
  }

  /** update the form's initial state and current state */
  setInitialState(initialState: Partial<TState>) {
    Object.assign(this.initialState, cloneDeep(initialState))
    Object.assign(this.state, cloneDeep(initialState))
  }

  /** add error message to this.errors array */
  addError({ field, message }: ValidationError) {
    // make sure the error message isn't already in the errors array
    for (const error of this.errors) {
      if (error.field === field && error.message === message) return
    }
    this.errors.push({ field, message })
  }

  /** add multiple errors to this.errors array */
  addErrors(...errors: ValidationError[]) {
    const { added } = ArrayUtils.diff(this.errors, errors)
    this.errors.push(...added)
  }

  /** remove error message from this.errors array */
  removeErrors() {
    this.errors.clear()
  }
}

/** Initialize a new formStore using the provided mutation and initial state */
export const useFormStore = <
  TMutation extends FormMutation<any>,
  TState extends FormState = InputFormMutation<TMutation>
>(
  mutation: GraphQLTaggedNode,
  initialState: TState,
  settings: Partial<FormSettings<TMutation>> = {}
): FormStore<TState, TMutation> => {
  const { showErrorToast = true, ...restSettings } = settings
  const [commitMutation] = useMutation<TMutation>(mutation)
  const [store] = useState(
    () =>
      new FormStore<TState, TMutation>(initialState, commitMutation, {
        ...restSettings,
        showErrorToast,
      })
  )

  // Display toast whenever form receives errors.
  useEffect(() => {
    return autorun(() => {
      if (store.errors.length === 0 || !showErrorToast) return
      displayGraphQLErrorToast(toJS(store.errors[0]))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store])

  // Clear errors when user makes a change.
  useEffect(() => {
    return deepObserve(store.state, () => store.removeErrors())
  }, [store, store.state])

  return store
}

/**
 * A relay mutation that recevies an "input" variable and returns a "response"
 * contains either errors or the mutated data
 */
export type FormMutation<TData extends Record<string, unknown>> = MutationParameters & {
  variables: { input: TData; connections?: ReadonlyArray<string> }
  response: {
    readonly response:
      | GlobalID
      | {
          readonly node?: {
            readonly id: GlobalID
          } | null
          readonly errors: ReadonlyArray<ValidationError> | null
        }
      | {
          readonly data?: Record<string, unknown> | null
          readonly errors: ReadonlyArray<ValidationError> | null
        }
  }
}

/** Get the input variable's type from a FormMutation */
type InputFormMutation<TMutation extends FormMutation<any>> =
  TMutation["variables"]["input"]

/** The function returned by relay to commit a FormMutation */
type CommitFormMutationFn<TMutation extends FormMutation<any>> = (
  config: UseMutationConfig<TMutation>
) => Disposable

/** The state of a form, i.e. the values being changed */
type FormState = Record<string, unknown>

/** Transform a plain interface to be its MobX Observable type */
export type ObservableState<T extends object> = {
  // Transform non-nullable arrays to observables
  [k in keyof T]: T[k] extends Array<infer I> | ReadonlyArray<infer I>
    ? IObservableArray<I extends object ? ObservableState<I> : I>
    : // Transform nullable arrays to observable
    T[k] extends Array<infer I> | ReadonlyArray<infer I> | null | undefined
    ? IObservableArray<I extends object ? ObservableState<I> : I> | null | undefined
    : T[k] extends ObservableSet<infer I> | Set<infer I>
    ? ObservableSet<I>
    : // Rescursively make nested objects observable
    T[k] extends object
    ? ObservableState<T[k]>
    : // Scalar values remain scalar
      T[k]
}

/** Makes the provided set of keys disallowed in an object */
type Impossible<K extends keyof any> = {
  [P in K]: never
}

/** Do not allow any properties beyond the set in T */
type NoExtraProperties<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>
