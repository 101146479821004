import { SignUpQueryParams } from "@/authentication/create-community/CreateCommunityPage"
import {
  CommunityTemplateKind,
  CreateCommunityPageMutation$variables,
  ValidateCommunityCreationStepInput,
} from "@/authentication/create-community/__generated__/CreateCommunityPageMutation.graphql"
import { BadgeKind } from "@/community/__generated__/CommunityBadgeFragment.graphql"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { OnboardingContextValidateMutation } from "@/core/context/__generated__/OnboardingContextValidateMutation.graphql"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { userIsNew } from "@utils/onboarding/onboardingUtils"
import { TestIDProps, ValueOf } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

interface OnboardingContextProps extends TestIDProps {
  children: ReactNode
}

export const ICP_PROFILE_MAP: Partial<Record<CommunityTemplateKind, string>> = {
  virtual_academy: "Virtual Academy",
  customer_education: "Customer Education",
  accelerator: "Accelerator",
  partner_education: "Partner Education",
  professional_association: "Professional Association",
  membership_community: "Membership Community",
  upskilling: "Upskilling",
  onboarding: "Onboarding",
  compliance: "Compliance",
}

type OnboardingContextValue = {
  form: OnboardingValidateFormStore
  hasProfileStep: boolean
  maxStep: number
  showLoadingScreen: boolean
  setShowLoadingScreen: Dispatch<SetStateAction<boolean>>
  /** is the onboarding in progress */
  inProgress: boolean
  setInProgress: Dispatch<SetStateAction<boolean>>
}

export type OnboardingAttribute =
  | "signup_source"
  | "disco_space_stage"
  | "disco_company_domain"
  | "disco_space_size"
  | "disco_space_use_case_group"
  | "icp_profile"

export function useOnboardingContext() {
  const context = useContext(OnboardingContext)
  if (!context) {
    throw new Error("useOnboardingContext used outside of OnboardingProvider")
  }
  return context
}

export type OnboardingContextValidateFormState = Omit<
  ValidateCommunityCreationStepInput,
  "attributes"
> & {
  attributes: { name: OnboardingAttribute; value: string }[]
}

export type OnboardingValidateFormStore = FormStore<
  OnboardingContextValidateFormState,
  OnboardingContextValidateMutation
>

const BADGE_COLORS = [
  "#4484FF",
  "#20CA8D",
  "#A78BFA",
  "#F56D6D",
  "#FA8F58",
  "#FFD762",
  "#FF78E9",
]

const OnboardingContext = createContext<OnboardingContextValue | null>(null)

function OnboardingProvider(props: OnboardingContextProps) {
  const { children } = props

  const location = useLocation()
  const [inProgress, setInProgress] = useState(false)
  const history = useHistory()
  const { authUser: user } = useAuthUser()
  const hasProfileStep = userIsNew(user?.firstName, user?.lastName)

  const [showLoadingScreen, setShowLoadingScreen] = useState(false)

  // Capture any marketing query params if they exist
  const { s } = useQueryParams<SignUpQueryParams>()

  const state = location.state as
    | CreateCommunityPageMutation$variables["input"]
    | undefined

  const validationForm = useFormStore<
    OnboardingContextValidateMutation,
    OnboardingContextValidateFormState
  >(
    graphql`
      mutation OnboardingContextValidateMutation(
        $input: ValidateCommunityCreationStepInput!
      ) {
        response: validateCommunityCreationStep(input: $input) {
          errors {
            field
            message
          }
        }
      }
    `,
    {
      step: "organization",
      organization: {
        badge: {
          color:
            state?.organization?.badge?.kind === "emoji"
              ? "#ffffff00"
              : state?.organization?.badge?.color || getDefaultCommunityBadge().color,
          kind: state?.organization?.badge?.kind || getDefaultCommunityBadge().kind,
          emoji: state?.organization?.badge?.emoji,
          mediaUrl: state?.organization?.badge?.mediaUrl,
          icon: state?.organization?.badge?.icon,
          assetId: state?.organization?.badge?.assetId,
        },
        coverUrl: state?.organization?.coverUrl || null,
        logo: state?.organization?.logo || null,
        name: state?.organization?.name || "",
        slug: state?.organization?.slug || "",
      },
      organizationDescription: {
        description: state?.organizationDescription?.description || "",
      },
      user: state?.user
        ? state.user
        : {
            firstName: "",
            lastName: "",
          },
      attributes: s ? [{ name: "signup_source", value: s }] : [],
      template: null,
    },
    {
      requireChangeToSubmit: !state?.organization,
    }
  )

  // Redirect to the first step if the user is on an onboarding page but onboarding is not in progress
  useEffect(() => {
    if (
      Object.values(ROUTE_NAMES.ONBOARDING.V2).includes(
        location.pathname as ValueOf<typeof ROUTE_NAMES.ONBOARDING.V2>
      ) &&
      !inProgress
    ) {
      history.replace(ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED)
    }
  }, [location.pathname, inProgress, history])

  return (
    <OnboardingContext.Provider
      value={{
        form: validationForm,
        hasProfileStep,
        maxStep: hasProfileStep ? 7 : 6,
        showLoadingScreen,
        setShowLoadingScreen,
        inProgress,
        setInProgress,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )

  function getDefaultCommunityBadge() {
    const color = BADGE_COLORS[Math.floor(Math.random() * BADGE_COLORS.length)]

    return {
      color,
      kind: "icon" as BadgeKind,
    }
  }
}

export default OnboardingProvider
